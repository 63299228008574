'use client';

import type { ImageProps } from 'next/image';
import Image from 'next/image';
import type React from 'react';
import { useState } from 'react';

interface ImageWithFallbackProps extends ImageProps {
  fallback: React.ReactNode;
}

export default function ImageWithFallback({ src, fallback, ...props }: ImageWithFallbackProps) {
  const [hasError, setHasError] = useState(false);

  if (hasError) {
    return fallback;
  }

  return <Image {...props} src={src} onError={() => setHasError(true)} />;
}
