import type { ProjectMetricsValidator, ProjectValidator } from '@trustblock/validators/project.validators';
import type { AuditPublic, IssuesCount } from './audit.types';
import type { AuditorPublic } from './auditor.types';
import type { Chain, CountByChain } from './chain.types';
import type { ContractPublicDeterministic, ContractType } from './contract.types';
import {
  type DistributedPick,
  type LinksPublic,
  type RequestResponseBase,
  type RequestResponsePagination,
  Tag
} from './global.types';

export interface ProjectsCountByTag {
  total: number;
  [Tag.Analytics]: number;
  [Tag.Collectibles]: number;
  [Tag.Finance]: number;
  [Tag.Gaming]: number;
  [Tag.Other]: number;
  [Tag.Security]: number;
  [Tag.Social]: number;
  [Tag.Wallet]: number;
}

export type ProjectsCountByChain = CountByChain & {
  total: number;
};

export type ProjectMetricsUpdate = ProjectMetricsValidator;

type ProjectPrivateBase = Pick<ProjectValidator, 'id' | 'domain' | 'slug' | 'email' | 'name'>;

type ProjectPrivateMetrics = Pick<
  ProjectMetricsValidator,
  'totalValue' | 'totalUsers' | 'totalTransactions' | 'evaluatedAt' | 'hasAlerted'
>;

export type ProjectPrivate = ProjectPrivateBase & {
  chains: Chain[];
  tags: Tag[];
  links: LinksPublic;
  metrics: ProjectPrivateMetrics;
  createdAt: number;
};

type ProjectPublicBase = Pick<ProjectValidator, 'domain' | 'slug' | 'name'>;

export type ProjectPublic = ProjectPublicBase & {
  chains: Chain[];
  tags: Tag[];
  links: LinksPublic;
  issuesCount: IssuesCount;
  createdAt: number;
};

export enum ProjectWidgetColor {
  Light = 'light',
  Dark = 'dark'
}
export const projectWidgetColors = Object.values(ProjectWidgetColor);

export enum ProjectWidgetMode {
  Fit = 'fit',
  Fill = 'fill'
}
export const projectWidgetModes = Object.values(ProjectWidgetMode);

export type ProjectWidgetOptions = {
  color?: ProjectWidgetColor;
  mode?: ProjectWidgetMode;
  hideAuditNotFound?: boolean;
};

export enum ProjectWidgetErrorCode {
  ProjectNotFound = 'PROJECT_NOT_FOUND',
  InvalidRequest = 'INVALID_REQUEST'
}

export type GetProjectsSlugsResponse = RequestResponseBase & { items: string[] };

type GetProjectSecurityStateResponseDataAuditAuditor = Pick<
  AuditorPublic,
  | 'status'
  | 'slug'
  | 'name'
  | 'profilePictureUrl'
  | 'description'
  | 'links'
  | 'createdAt'
  | 'type'
  | 'foundingYear'
  | 'headcount'
  | 'isInactive'
>;

export type GetProjectSecurityStateResponseDataAudit = DistributedPick<
  AuditPublic,
  | 'id'
  | 'name'
  | 'description'
  | 'reportFileCid'
  | 'reportFileUrl'
  | 'reportUrl'
  | 'issues'
  | 'issuesCount'
  | 'conductedAt'
> & {
  auditor: GetProjectSecurityStateResponseDataAuditAuditor;
};

export type GetProjectSecurityStateResponseData = ProjectPublic & {
  hasCriticalIssuesNotFixed: boolean;
  auditedContractsOnChain: Pick<ContractPublicDeterministic<ContractType.OnChain>, 'chain' | 'evmAddress'>[];
  audits: GetProjectSecurityStateResponseDataAudit[];
};
export type GetProjectSecurityStateResponse = RequestResponseBase & GetProjectSecurityStateResponseData;

export type SearchProjectsResponseItemData = Pick<ProjectPublic, 'domain' | 'name' | 'slug' | 'issuesCount'> & {
  email: string;
  totalValue: number;
  lastAuditDate: number;
  lastAuditorName: string;
  links: LinksPublic;
  chains: Chain[];
  tags: Tag[];
};
export type SearchProjectsResponse = RequestResponseBase & RequestResponsePagination<SearchProjectsResponseItemData>;

export type GetProjectPageDataResponseData = ProjectPublic & {
  audits: (Pick<AuditPublic, 'issuesCount' | 'name' | 'conductedAt' | 'id' | 'contractsCountByChain'> & {
    auditor: {
      slug: string;
      name: string;
    };
  })[];
};
export type GetProjectPageDataResponse = RequestResponseBase & GetProjectPageDataResponseData;

export interface GetProjectWidgetPageDataResponseData {
  slug: string;
  domain: string;
  lastAuditDate: number;
  hasCriticalIssuesNotFixed: boolean;
  lastAuditorName: string;
}
export type GetProjectWidgetPageDataResponse = RequestResponseBase & GetProjectWidgetPageDataResponseData;
