'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@trustblock/components/standalone/Button/Button';
import Form from '@trustblock/components/standalone/Form/Form';
import InputText from '@trustblock/components/standalone/Inputs/InputText/InputText';
import { searchProjectsValidator } from '@trustblock/validators/project.validators';
import { useQueryStates } from 'nuqs';
import { useTransition } from 'react';
import { useForm } from 'react-hook-form';
import { searchParser } from '../common';

const searchProjectsLocalValidator = searchProjectsValidator.omit({ page: true });

export function HomePageProjectsForm() {
  const [isPending, startTransition] = useTransition();

  const [{ searchTerm }, setParams] = useQueryStates(searchParser, { startTransition });
  const formMethods = useForm({
    resolver: zodResolver(searchProjectsLocalValidator),
    defaultValues: {
      searchTerm
    }
  });
  const { register, handleSubmit } = formMethods;

  const search = handleSubmit((data) => {
    startTransition(() => {
      if (searchTerm !== data.searchTerm) {
        setParams({ searchTerm: data.searchTerm ?? '', page: '1' });
      }
    });
  });

  return (
    <Form className="grid w-full gap-1 grid-cols-[1fr_min-content]" onSubmit={search} formMethods={formMethods}>
      <InputText
        placeholder="Type a URL, domain, name or contract address"
        fieldName="searchTerm"
        register={register}
        autoComplete="false"
        data-lpignore="true"
        data-form-type="other"
      />
      <Button type="submit" icon="Search" onlyIcon fill mode="primary" loading={isPending} />
    </Form>
  );
}
