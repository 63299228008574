import { Chain } from '@trustblock/types/chain.types';
import { Tag } from '@trustblock/types/global.types';
import {
  createSearchParamsCache,
  parseAsInteger,
  parseAsString,
  parseAsStringEnum,
  parseAsStringLiteral
} from 'nuqs/server';

export const searchParser = {
  searchTerm: parseAsString.withDefault(''),
  page: parseAsString.withDefault('1'),
  tab: parseAsStringLiteral(['auditors', 'projects']).withDefault('projects'),
  chain: parseAsStringEnum<Chain>(Object.values(Chain)),
  tag: parseAsStringEnum<Tag>(Object.values(Tag)),
  tvs: parseAsInteger,
  name: parseAsString.withDefault('')
};

export const searchParamsCache = createSearchParamsCache(searchParser);
