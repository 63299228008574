import type {
  AuditorMetricsStatsSubValidator,
  AuditorMetricsValidator,
  AuditorValidator
} from '@trustblock/validators/auditor.validators';
import type { AuditPublic, IssuesCount } from './audit.types';
import type { ContractsCountByChain } from './contract.types';
import type { RequestResponseBase, RequestResponsePagination } from './global.types';
import type { ProjectsCountByChain, ProjectsCountByTag } from './project.types';
import type { UserPrivate, UserPublic, UserType } from './user.types';

export enum AuditorType {
  Firm = 'firm',
  DaoCommunity = 'dao_community',
  Individual = 'individual'
}
export const auditorTypes = Object.values(AuditorType);

export enum AuditorHeadcount {
  OneToFive = 'one_to_five',
  FiveToTwenty = 'five_to_twenty',
  TwentyToFifty = 'twenty_to_fifty',
  FiftyPlus = 'fifty_plus'
}
export const auditorHeadcounts = Object.values(AuditorHeadcount);

export enum ProspectChain {
  Evm = 'evm',
  Cosmos = 'cosmos',
  Solana = 'solana',
  Near = 'near',
  Starknet = 'starknet',
  Substrate = 'substrate'
}
export const prospectChains = Object.values(ProspectChain);

export type AuditorMetricsStatsSubPrivate = AuditorMetricsStatsSubValidator;
export type AuditorMetricsPrivate = AuditorMetricsValidator & {
  value: AuditorMetricsStatsSubValidator;
  users: AuditorMetricsStatsSubValidator;
  transactions: AuditorMetricsStatsSubValidator;
};

type AuditorPrivateBase = Pick<AuditorValidator, 'type' | 'foundingYear' | 'headcount' | 'isInactive'> &
  Pick<
    UserPrivate,
    | 'id'
    | 'authId'
    | 'status'
    | 'slug'
    | 'name'
    | 'profilePictureUrl'
    | 'description'
    | 'apiKey'
    | 'apiCreditsCount'
    | 'links'
    | 'createdAt'
  >;

type AuditorPrivateMetricsBase = Pick<AuditorMetricsValidator, 'evaluatedAt'>;

export type AuditorPrivate = AuditorPrivateBase & {
  metrics: AuditorPrivateMetricsBase & {
    value: AuditorMetricsStatsSubValidator;
    users: AuditorMetricsStatsSubValidator;
    transactions: AuditorMetricsStatsSubValidator;
  };
  userType: UserType.Auditor;
};

type AuditorPublicBase = Pick<AuditorValidator, 'type' | 'foundingYear' | 'headcount' | 'isInactive'> &
  Pick<UserPublic, 'status' | 'slug' | 'name' | 'profilePictureUrl' | 'description' | 'links' | 'createdAt'>;

type AuditorPublicMetricsItem = Pick<AuditorMetricsStatsSubValidator, 'total' | 'average' | 'min' | 'max'>;

export type AuditorPublic = AuditorPublicBase & {
  metrics: {
    value: AuditorPublicMetricsItem;
    users: AuditorPublicMetricsItem;
    transactions: AuditorPublicMetricsItem;
  };
  issuesCount: IssuesCount;
  contractsCountByChain: ContractsCountByChain;
  projectsCountByChain: ProjectsCountByChain;
  projectsCountByTag: ProjectsCountByTag;
};

export interface CreateAuditorResponseData {
  slug: string;
}
export type CreateAuditorResponse = RequestResponseBase & CreateAuditorResponseData;

export type UpdateAuditorResponse = RequestResponseBase;

export type GetAuditorsSlugsResponse = RequestResponseBase & { items: string[] };

export type SearchAuditorsResponseItemData = Pick<AuditorPublic, 'name' | 'profilePictureUrl' | 'slug'> & {
  tvs: number;
  auditsTotal: number;
  projectsCountByChain: ProjectsCountByChain;
  projectsCountByTag: ProjectsCountByTag;
};
export type SearchAuditorsResponse = RequestResponseBase & RequestResponsePagination<SearchAuditorsResponseItemData>;

export type GetAuditorPageDataResponseData = AuditorPublic & {
  email: string;
  audits: (Pick<AuditPublic, 'issuesCount' | 'name' | 'conductedAt' | 'id'> & {
    project: {
      name: string;
      slug: string;
    };
    auditor: {
      slug: string;
      name: string;
    };
    projectsCountByChain: ProjectsCountByChain;
    contractsCountByChain: ContractsCountByChain;
  })[];
};
export type GetAuditorPageDataResponse = RequestResponseBase & GetAuditorPageDataResponseData;

export type GetAuditorWidgetPageDataResponseData = Pick<AuditorPublic, 'slug' | 'metrics' | 'name'> & {
  domain: string;
  auditsTotal: number;
  issuesTotal: number;
};
export type GetAuditorWidgetPageDataResponse = RequestResponseBase & GetAuditorWidgetPageDataResponseData;

export type GetUploadReportAuthorizationResponse = RequestResponseBase & {
  url: string;
  apiKey: string;
  fileName: string;
};
