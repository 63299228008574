import type { AuditIssueValidator, AuditValidator } from '@trustblock/validators/audit.validators';
import type { SearchAuditorsResponseItemData } from './auditor.types';
import type { CountByChain } from './chain.types';
import type { ContractPublic } from './contract.types';
import type { ContractsCountByChain } from './contract.types';
import type { CreateAuditErrorCode, CreateContractsErrorCode } from './error.types';
import type { DeepPartial, DistributedPick, RequestResponseBase, Tag } from './global.types';
import type { ProjectPublic, SearchProjectsResponseItemData } from './project.types';

export enum AuditType {
  File = 'file',
  Web = 'web'
}
export const auditTypes = Object.values(AuditType);

export enum IssueSeverity {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Critical = 'critical'
}
export const issueSeverities = Object.values(IssueSeverity);

export enum IssueStatus {
  Fixed = 'fixed',
  NotFixed = 'not_fixed'
}
export const issueStatuses = Object.values(IssueStatus);

export enum CreateAuditProjectMode {
  Assign = 'assign',
  Create = 'create'
}

export interface IssuesCount {
  total: number;
  [IssueStatus.Fixed]: {
    total: number;
    [IssueSeverity.Low]: number;
    [IssueSeverity.Medium]: number;
    [IssueSeverity.High]: number;
    [IssueSeverity.Critical]: number;
  };
  [IssueStatus.NotFixed]: {
    total: number;
    [IssueSeverity.Low]: number;
    [IssueSeverity.Medium]: number;
    [IssueSeverity.High]: number;
    [IssueSeverity.Critical]: number;
  };
}

export interface IssuesCountFromAI {
  [IssueStatus.Fixed]: {
    [IssueSeverity.Low]: number;
    [IssueSeverity.Medium]: number;
    [IssueSeverity.High]: number;
    [IssueSeverity.Critical]: number;
  };
  [IssueStatus.NotFixed]: {
    [IssueSeverity.Low]: number;
    [IssueSeverity.Medium]: number;
    [IssueSeverity.High]: number;
    [IssueSeverity.Critical]: number;
  };
}

export interface IssuesCountOptionalTotal {
  total?: number;
  [IssueStatus.Fixed]: {
    total?: number;
    [IssueSeverity.Low]: number;
    [IssueSeverity.Medium]: number;
    [IssueSeverity.High]: number;
    [IssueSeverity.Critical]: number;
  };
  [IssueStatus.NotFixed]: {
    total?: number;
    [IssueSeverity.Low]: number;
    [IssueSeverity.Medium]: number;
    [IssueSeverity.High]: number;
    [IssueSeverity.Critical]: number;
  };
}

export type AuditIssuePrivate = Pick<AuditIssueValidator, 'id' | 'status' | 'severity' | 'name' | 'description'>;
export type AuditIssuePublic = Pick<AuditIssueValidator, 'status' | 'severity' | 'name' | 'description'>;

export type AuditPrivate = DistributedPick<
  AuditValidator,
  'id' | 'auditorId' | 'projectId' | 'name' | 'description' | 'reportFileCid' | 'reportUrl' | 'reportType'
> & {
  issues: AuditIssuePrivate[];
  reportFileUrl?: string;
  createdAt: number;
  conductedAt: number;
};

export type AuditPrivateDeterministic<T extends AuditType> = AuditPrivate & {
  reportType: T;
  reportFileUrl: T extends AuditType.File ? string : never;
};

export type AuditPublic = DistributedPick<
  AuditValidator,
  'id' | 'auditorId' | 'projectId' | 'name' | 'description' | 'reportFileCid' | 'reportUrl' | 'reportType'
> & {
  issues: AuditIssuePublic[];
  reportFileUrl?: string;
  issuesCount: IssuesCount;
  conductedAt: number;
  contractsCountByChain: CountByChain;
};

export type CreateAuditResponse = RequestResponseBase & {
  id: string;
};
export type CreateAuditResponseError = RequestResponseBase & {
  code: CreateAuditErrorCode | CreateContractsErrorCode;
};

export type GetAuditPageDataResponseDataContract = DistributedPick<
  ContractPublic,
  | 'type'
  // on-chain
  | 'chain'
  | 'evmAddress'
  // off-chain
  | 'repositoryUrl'
  | 'repositoryCommitHash'
  | 'repositoryFilePath'
  // secret
  | 'name'
>;

export type GetAuditPageDataResponseData = DistributedPick<
  AuditPublic,
  | 'description'
  | 'id'
  | 'issues'
  | 'issuesCount'
  | 'name'
  | 'reportFileCid'
  | 'reportUrl'
  | 'reportFileUrl'
  | 'reportType'
  | 'contractsCountByChain'
  | 'conductedAt'
> & {
  project: SearchProjectsResponseItemData;
  auditor: SearchAuditorsResponseItemData;
  contracts: GetAuditPageDataResponseDataContract[];
  contractsCountByChain: ContractsCountByChain;
};

export type GetAuditPageDataResponse = RequestResponseBase & GetAuditPageDataResponseData;

export type GetAuditsSlugsResponseItemData = {
  items: string[];
};

export type GetAuditsSlugsResponse = RequestResponseBase & GetAuditsSlugsResponseItemData;

export type ExtractAuditInfoResponseData = DeepPartial<Pick<AuditPublic, 'name' | 'description' | 'conductedAt'>> & {
  project: DeepPartial<Pick<ProjectPublic, 'name' | 'links'>> & {
    tags?: Tag[];
  };
  issuesCount: IssuesCount;
};

export type ExtractAuditInfoResponse = RequestResponseBase & ExtractAuditInfoResponseData;
